/* You can add global styles to this file, and also import other style files */

html, body { background-color: #f1f2f7;transition: all 0.5s;}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400&display=swap');


body { margin: 0; padding:0; box-sizing:border-box;font: Roboto, "Helvetica Neue", sans-serif; }

input[type=file]::file-selector-button {
    
    padding: 5px 10px;
    border-radius: .3em;
    background-color: #3f51b5;
    color: #f5f5f5f5;
    transition: 1s;
    outline: none;
    border:none;

  }
  
  mat-card {
    background: #fff;
    min-height: 50px;
    box-shadow: none !important;
    border: 0;
    position: relative;
    border-radius: 10px !important;

  }

mat-table{
  transition: none !important;
  box-shadow: none !important;
}


.title{
  color: #6777ef;
}

h2 .title :hover{
  color: #bac1f7 !important;
}

.title2{
  color: #919aa3;
}

.title_login {
  color: #212529;
}

/* .mat-raised-button {
  background-color: #f49a2ede !important;
}

.mat-flat-button {
  background-color: #6777ef !important;
} */

